module.exports = [{
      plugin: require('/tmp/6e912d6c/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134611132-1"},
    },{
      plugin: require('/tmp/6e912d6c/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/6e912d6c/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/6e912d6c/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
